import React from "react"
import { Container, Col, Row, Table, Image, Figure } from "react-bootstrap"

import Layout from "../../components/layoutHome"
import Model from "../../components/Models/biblioteca"
import SEO from "../../components/seo"

const Biblioteca = () => {
  return (
    <Layout>
      <Container>
      <SEO title={Model.title}/>
        <Row>
          <Col>
            <h1>{Model.title}</h1>
          </Col>
        </Row>
        <Row>
          <Col xl={12} >
            <Figure>
              <Figure.Image src="https://sitimm-files.nyc3.digitaloceanspaces.com/ceforma_biblioteca/01.JPG" />
            </Figure>
          </Col>
          
        </Row>
        <Row>
          <Col xl={12} style={{
            textAlign:"justify"
          }}>{Model.mainText1}</Col>
        </Row>
        <Row>
          <Col style={{
            textAlign:"justify"
          }} >{Model.mainText2}</Col>
        </Row>
        <Row>
          <Col>
            <strong> Metas del proyecto cultural: </strong>
          </Col>
        </Row>

        <Row>
          <Col>
            {Model.metas.map(meta => {
              return <li>{meta}</li>
            })}
          </Col>
        </Row>
        <Row>
          <Col md={12} xl={4}>
            <Figure>
              <Figure.Image src="https://sitimm-files.nyc3.digitaloceanspaces.com/ceforma_biblioteca/02.JPG" />
            </Figure>
          </Col>
          <Col md={12} xl={4}>
            <Figure>
              <Figure.Image src="https://sitimm-files.nyc3.digitaloceanspaces.com/ceforma_biblioteca/03.JPG" />
            </Figure>
          </Col>
          <Col md={12} xl={4}>
            <Figure>
              <Figure.Image src="https://sitimm-files.nyc3.digitaloceanspaces.com/ceforma_biblioteca/04.JPG" />
            </Figure>
          </Col>
        </Row>
        <Row>
          <Col style={{
            textAlign:"justify"
          }} >
            <p>
              Este programa totalmente incluyente reforzará el desarrollo
              cultural a través de la entrega de equipamiento desarrollado
              especialmente para la población con discapacidad, que consta de:
            </p>
          </Col>
        </Row>
        <Row>
          <Col style={{ color: "white", backgroundColor: "#17365D" }}>
            <h5>Equipamento para personas con discapacidad</h5>
          </Col>
        </Row>
        <Row>
          <Col>
            <Table style={{
            textAlign:"justify"
          }}>
              <tbody>
                {Model.equipoDiscapacidad.map(equipo => (
                  <tr>
                    <td>{equipo.equipo}</td>
                    <td>{equipo.descripcion}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Col>
        </Row>
        <Row>
          <Col md={12} xl={12}>
            <p>
              La Biblioteca Formativa Laboral Itinerante cuenta con el siguiente
              equipo:
            </p>
            <Table style={{ textAlign: "center" }}>
              <thead
                style={{
                  color: "white",
                  backgroundColor: "black",
                }}
              >
                <tr>
                  <th>Concepto</th>
                  <th>Unidad</th>
                  <th>Cantidad</th>
                </tr>
              </thead>
              <tbody>
                {Model.equipo.map(equipo => (
                  <tr>
                    <td>{equipo.concepto}</td>
                    <td>{equipo.unidad}</td>
                    <td>{equipo.cantidad}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Col>
        </Row>
        <Row>
          <Col md={12} xl={12}>
            <Container>
              <Row>
                <Col>
                  <Figure>
                    <Figure.Image src="https://sitimm-files.nyc3.digitaloceanspaces.com/ceforma_biblioteca/05.JPG" />
                  </Figure>
                </Col>
                <Col>
                  <Figure>
                    <Figure.Image src="https://sitimm-files.nyc3.digitaloceanspaces.com/ceforma_biblioteca/06.JPG" />
                  </Figure>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Figure>
                    <Figure.Image src="https://sitimm-files.nyc3.digitaloceanspaces.com/ceforma_biblioteca/07.JPG" />
                  </Figure>
                </Col>
                <Col>
                  <Figure>
                    <Figure.Image src="https://sitimm-files.nyc3.digitaloceanspaces.com/ceforma_biblioteca/08.JPG" />
                  </Figure>
                </Col>
              </Row>
            </Container>
          </Col>
        </Row>
        <Row>
          <Col>
            <strong>Descripción del impacto cultural del proyecto:</strong>
          </Col>
        </Row>
        <Row>
          <Col>
            La Biblioteca Formativa Laboral Itinerante, permitirá cubrir gran
            parte del territorio, logrando los siguientes objetivos:
          </Col>
        </Row>
        <Row>
        <Col>
          <ol>
            <li style={{ backgroundColor: "#f5f5f5" }}>{Model.impacto[0]}</li>
            <li>{Model.impacto[1]}</li>
            <li style={{ backgroundColor: "#f5f5f5" }}>{Model.impacto[2]}</li>
            <li>{Model.impacto[3]}</li>
          </ol>
        </Col>
      </Row>
      </Container>

    </Layout>
  )
}

export default Biblioteca
