module.exports = {
    title: "Biblioteca Formativa Laboral Itinerante",
    mainText1: `
    La Biblioteca Formativa Laboral Itinerante es un aula móvil digital, que cuenta con servicio de Internet y equipamiento electrónico para ofrecer de forma gratuita a la población actividades culturales, recreativas y educativas. Además, contará con una Biblioteca Virtual, herramienta que permitirá atender a los distintos usuarios a lo largo y ancho del Estado de Guanajuato.
  
    Esta Biblioteca Itinerante, cuenta con una serie de herramientas que facilitan la recuperación y administración de libros con materiales seleccionados especialmente para cubrir las necesidades de todos los rangos de edad y en específico de los distintos niveles educativos: estudiantes de nivel básico, bachillerato, licenciatura y posgrado. Los recursos electrónicos fueron seleccionados conforme a la demanda de la población objetivo, atendiendo primordialmente a aquella población en edad académica y ofreciendo contenidos digitales específicos para el desempeño de actividades educativas y culturales.
      `,
    mainText2: `
    Además, estos recursos permiten ampliar las posibilidades de acceso a la cultura beneficiando a todas las localidades del Municipio, promoviendo, el acercamiento a las tecnologías de la información y la formación para el trabajo.
    
    Asimismo, posibilita el acceso a aulas de capacitación laboral sin importar la ubicación geográfica de las personas, apoyando, a los programas de educación a distancia de las dependencias del Gobierno del Municipio y del Estado.
    `,
    metas: [
      `Lograr que la Biblioteca Formativa Laboral Itinerante permita un acercamiento a las comunidades que más lo necesitan. `,
      `Lograr que la Biblioteca Itinerante provea de dinamismo y movilidad a las localidades con mayor necesidad de atención. `,
      `Mejorar la calidad de asistencia y con ello la satisfacción del usuario y profesionales. `,
      `Lograr la incorporación de los beneficios de la Biblioteca Virtual contenida en la unidad. `,
      `Contar con mayor alcance en el uso de las herramientas electrónicas. `,
      `Impulsar la cultura, la educación y los cursos de formación para el trabajo. `,
      `Lograr el decremento de la brecha digital. `,
      `Promover un desarrollo económico sustentable. `,
      `Aumentar la participación ciudadana. `,
      `Coadyuvar a la equidad de oportunidades. `,
    ],
    equipoDiscapacidad: [
      {
        equipo: "2 Computadoras* ",
        descripcion: "Computadora LENOVO All in One Mod. C260 ",
      },
      {
        equipo: "Software NVDA ",
        descripcion:
          "Es un software de lectura de pantalla para el sistema operativo Microsoft Windows, instalado en cada una de las computadoras.",
      },
      {
        equipo: "Licencias de Finereader",
        descripcion:
          "Una licencia, instalada por computadora, para Finereader, el cual es uno de los mejores reconocedores de caracteres adaptados para personas con discapacidad visual.",
      },
      {
        equipo: "2 Escáner de cama plana",
        descripcion:
          "Es un escáner que será incorporado a cada equipo y que en conjunto con el Finereader y con el software NVDA lograrán que las personas ciegas o con baja visión puedan realizar las siguientes funciones: Acceder a la lectura de cualquier documento impreso en tinta ya sea para ser leído con voz por la computadora mediante el lector de pantalla, para ser digitalizado y guardado en documento de texto en distintos formatos o, digitalizar el libro con voz para convertirlo en libro hablado. Convertir documentos de diferentes extensiones como, .PDF, .JPG, .PNG, Word, Excel u otros formatos editables en la computadora.",
      },
    ],
    equipo: [
      { concepto: "Vehículo", unidad: "Pza", cantidad: "1" },
      { concepto: "Conversión", unidad: "Pza", cantidad: "1" },
      { concepto: "Computadoras", unidad: "Pza", cantidad: "11" },
      { concepto: "Tabletas electrónicas", unidad: "Pza", cantidad: "10" },
      { concepto: "Impresora Multifuncional", unidad: "Pza", cantidad: "1" },
      { concepto: "Pantalla 55'", unidad: "Pza", cantidad: "1" },
      { concepto: "Proyector", unidad: "Pza", cantidad: "1" },
      { concepto: "Sillas con riñonera y brazos", unidad: "Pza", cantidad: "11" },
      { concepto: "Centros de trabajo", unidad: "Pza", cantidad: "11" },
      { concepto: "Núcleo de WC", unidad: "Pza", cantidad: "1" },
      { concepto: "Pantalla de pared 60'", unidad: "Pza", cantidad: "1" },
      { concepto: "Internet", unidad: "Servicio", cantidad: "1" },
    ],
    impacto: [
      `Promover el uso de las herramientas tecnológicas asociadas con el funcionamiento de la Biblioteca Formativa Laboral Itinerante, impulsando de manera sólida el desarrollo de la Cultura Digital. `,
      `Dotar a la población del Municipio de Irapuato con contenidos culturales - digitales que permitan, de manera efectiva, impulsar el desarrollo artístico cultural y la formación laboral a través de cursos. `,
      `Contar con un mayor alcance en el uso de herramientas electrónicas gratuitas, promoviendo la disminución de la brecha digital en el Municipio de Irapuato y logrando, de este modo, favorecer a los sectores que más necesitan este apoyo. `,
      `La Biblioteca Formativa Laboral Itinerante proyecta un enfoque dual sobre la educación y la cultura pues es en la cultura y las artes donde se centra el eje del proyecto. `,
    ],
  }
  